import { createReducer } from '@reduxjs/toolkit';
import { ThunkDispatch } from 'redux-thunk';

import { SokBehandlingerResponse, Behandling } from '../../types/Helsenorge.VelgBehandlingssted.Libraries.EntitiesEntities';

import { Suggestion } from '@helsenorge/autosuggest/components/autosuggest';
import { get } from '@helsenorge/framework-utils/hn-proxy-service';
import { error } from '@helsenorge/framework-utils/logger';

import { GlobalState, GlobalAction } from '../../root';

/** ACTION TYPES */

const SOK_BEHANDLINGER_LOAD = 'velg-behandlingssted/SOK_BEHANDLINGER_LOAD';
const SOK_BEHANDLINGER_LOAD_SUCCESS = 'velg-behandlingssted/SOK_BEHANDLINGER_LOAD_SUCCESS';
const SOK_BEHANDLINGER_LOAD_FAIL = 'velg-behandlingssted/SOK_BEHANDLINGER_LOAD_FAIL';

/** ACTIONS */

interface SokBehandlingerLoadAction {
  type: typeof SOK_BEHANDLINGER_LOAD;
}

interface SokBehandlingerLoadSuccessAction {
  type: typeof SOK_BEHANDLINGER_LOAD_SUCCESS;
  behandlinger: Array<Behandling> | null;
}

interface SokBehandlingerLoadFailAction {
  type: typeof SOK_BEHANDLINGER_LOAD_FAIL;
}

export type SokBehandlingerAction = SokBehandlingerLoadAction | SokBehandlingerLoadSuccessAction | SokBehandlingerLoadFailAction;

/** ACTION CREATORS */

export const loadSokBehandlinger = (): SokBehandlingerLoadAction => {
  return { type: SOK_BEHANDLINGER_LOAD };
};

export const successSokBehandlinger = (behandlinger: Array<Behandling> | null): SokBehandlingerLoadSuccessAction => {
  return { type: SOK_BEHANDLINGER_LOAD_SUCCESS, behandlinger: behandlinger };
};

export const failedSokBehandlinger = (): SokBehandlingerLoadFailAction => {
  return { type: SOK_BEHANDLINGER_LOAD_FAIL };
};

/** REDUCER */

export type SokBehandlingerState = {
  loading: boolean;
  behandlinger?: Array<Behandling> | null;
};

export const initialState = { loading: false };

const sokBehandlinger = createReducer<SokBehandlingerState>(initialState, {
  [SOK_BEHANDLINGER_LOAD]: state => {
    state.loading = true;
  },
  [SOK_BEHANDLINGER_LOAD_SUCCESS]: (state, action: SokBehandlingerLoadSuccessAction) => {
    state.loading = false;
    state.behandlinger = action.behandlinger;
  },
  [SOK_BEHANDLINGER_LOAD_FAIL]: state => {
    state.loading = false;
  },
});

/** STATE SELECTORS */

export function getSokBehandlingerState(state: GlobalState): SokBehandlingerState {
  if (!state.sokBehadlinger) {
    error('the sokBehandlinger state is missing in this reducer');
  }
  return state.sokBehadlinger;
}

export function getSokBehandlingerLoading(state: GlobalState): boolean {
  return getSokBehandlingerState(state).loading;
}

export function getSokBehandlinger(state: GlobalState): Array<Behandling> | undefined | null {
  return getSokBehandlingerState(state).behandlinger;
}

export function getSokBehandlingerSuggestions(state: GlobalState): Array<Suggestion> {
  const behandlinger: Array<Behandling> | undefined | null = getSokBehandlingerState(state).behandlinger;
  let suggestions: Array<Suggestion> = [];

  if (behandlinger && behandlinger.length > 0) {
    suggestions = behandlinger.map(item => {
      return {
        label: item.navn ? item.navn : '',
        value: item.behandlingsId ? item.behandlingsId : '',
        optionalLabel: item.synonym ? `${item.synonym}` : '',
      };
    });
  }

  return suggestions;
}

/** API */

export function hentSokBehandlinger() {
  return (dispatch: ThunkDispatch<GlobalState, void, GlobalAction>, getState: () => GlobalState): Promise<void> | undefined => {
    const state = getState();

    if (!getSokBehandlingerLoading(state) && !getSokBehandlinger(state)) {
      dispatch(loadSokBehandlinger());

      return get<SokBehandlingerResponse>('VelgBehandlingssted', 'api/v1/SokBehandlinger')
        .then((data: SokBehandlingerResponse) => {
          dispatch(successSokBehandlinger(data.behandlinger));
        })
        .catch(() => {
          dispatch(failedSokBehandlinger());
        });
    }
  };
}

export default sokBehandlinger;
