import { createReducer } from '@reduxjs/toolkit';
import { ThunkDispatch } from 'redux-thunk';

import {
  VentetiderForBehandlingsstedResponse,
  Behandlingsgruppe,
} from '../types/Helsenorge.VelgBehandlingssted.Libraries.EntitiesEntities';
import { HNVelgBehandlingsstedFrontend } from '../types/Resources';

import { get } from '@helsenorge/framework-utils/hn-proxy-service';
import { error } from '@helsenorge/framework-utils/logger';

import { GlobalState, GlobalAction } from '../root';

/** ACTION TYPES */

const VENTETIDER_FOR_BEHANDLINGSSTED_LOAD = 'velg-behandlingssted/VENTETIDER_FOR_BEHANDLINGSSTED_LOAD';
const VENTETIDER_FOR_BEHANDLINGSSTED_LOAD_SUCCESS = 'velg-behandlingssted/VENTETIDER_FOR_BEHANDLINGSSTED_LOAD_SUCCESS';
const VENTETIDER_FOR_BEHANDLINGSSTED_LOAD_FAIL = 'velg-behandlingssted/VENTETIDER_FOR_BEHANDLINGSSTED_LOAD_FAIL';

/** ACTIONS */

interface VentetiderForBehandlingsstedBaseAction {
  orgnr: string;
}

interface VentetiderForBehandlingsstedLoadAction extends VentetiderForBehandlingsstedBaseAction {
  type: typeof VENTETIDER_FOR_BEHANDLINGSSTED_LOAD;
}

interface VentetiderForBehandlingsstedLoadSuccessAction extends VentetiderForBehandlingsstedBaseAction {
  type: typeof VENTETIDER_FOR_BEHANDLINGSSTED_LOAD_SUCCESS;
  behandlingsGrupper: Array<Behandlingsgruppe> | null;
}

interface VentetiderForBehandlingsstedLoadFailAction extends VentetiderForBehandlingsstedBaseAction {
  type: typeof VENTETIDER_FOR_BEHANDLINGSSTED_LOAD_FAIL;
  error: string;
}

export type VentetiderForBehandlingsstedAction =
  | VentetiderForBehandlingsstedLoadAction
  | VentetiderForBehandlingsstedLoadSuccessAction
  | VentetiderForBehandlingsstedLoadFailAction;

/** ACTION CREATORS */

export const loadVentetiderForBehandlingssted = (orgnr: string): VentetiderForBehandlingsstedLoadAction => {
  return { type: VENTETIDER_FOR_BEHANDLINGSSTED_LOAD, orgnr: orgnr };
};

export const successVentetiderForBehandlingssted = (
  orgnr: string,
  behandlingsGrupper: Array<Behandlingsgruppe> | null
): VentetiderForBehandlingsstedLoadSuccessAction => {
  return { type: VENTETIDER_FOR_BEHANDLINGSSTED_LOAD_SUCCESS, orgnr: orgnr, behandlingsGrupper: behandlingsGrupper };
};

export const failedVentetiderForBehandlingssted = (error: string, orgnr: string): VentetiderForBehandlingsstedLoadFailAction => {
  return { type: VENTETIDER_FOR_BEHANDLINGSSTED_LOAD_FAIL, error: error, orgnr: orgnr };
};

/** STATE */

interface VentetiderForBehandlingssted {
  loading: boolean;
  loaded: boolean;
  error?: string;
  behandlingsGrupper?: Array<Behandlingsgruppe> | null;
}

export type VentetiderForBehandlingsstedState = {
  ventetiderForBehandlingsstedMap: { [orgnr: string]: VentetiderForBehandlingssted };
};

/** REDUCER */

export const initialState = { ventetiderForBehandlingsstedMap: {} };

const ventetiderForBehandlingssted = createReducer<VentetiderForBehandlingsstedState>(initialState, {
  [VENTETIDER_FOR_BEHANDLINGSSTED_LOAD]: (state, action: VentetiderForBehandlingsstedLoadSuccessAction) => {
    state.ventetiderForBehandlingsstedMap[action.orgnr] = { loading: true, loaded: false };
  },
  [VENTETIDER_FOR_BEHANDLINGSSTED_LOAD_SUCCESS]: (state, action: VentetiderForBehandlingsstedLoadSuccessAction) => {
    state.ventetiderForBehandlingsstedMap[action.orgnr].loading = false;
    state.ventetiderForBehandlingsstedMap[action.orgnr].loaded = true;
    state.ventetiderForBehandlingsstedMap[action.orgnr].behandlingsGrupper = action.behandlingsGrupper;
  },
  [VENTETIDER_FOR_BEHANDLINGSSTED_LOAD_FAIL]: (state, action: VentetiderForBehandlingsstedLoadFailAction) => {
    state.ventetiderForBehandlingsstedMap[action.orgnr].loading = false;
    state.ventetiderForBehandlingsstedMap[action.orgnr].error = action.error;
  },
});

/** STATE SELECTORS */

export function getVentetiderForBehandlingsstedState(state: GlobalState): VentetiderForBehandlingsstedState {
  if (!state.ventetiderForBehandlingssted) {
    error('the ventetiderForBehandlingssted state is missing in this reducer');
  }
  return state.ventetiderForBehandlingssted;
}

export function getVentetiderForBehandlingssted(state: GlobalState, orgnr: string): VentetiderForBehandlingssted | undefined {
  return getVentetiderForBehandlingsstedState(state).ventetiderForBehandlingsstedMap[orgnr];
}

export function getVentetiderForBehandlingsstedLoading(state: GlobalState, orgnr: string): boolean {
  const ventetiderForBehandlingssted = getVentetiderForBehandlingssted(state, orgnr);
  return ventetiderForBehandlingssted ? ventetiderForBehandlingssted.loading : false;
}

export function getVentetiderForBehandlingsstedLoaded(state: GlobalState, orgnr: string): boolean {
  const ventetiderForBehandlingssted = getVentetiderForBehandlingssted(state, orgnr);
  return ventetiderForBehandlingssted ? ventetiderForBehandlingssted.loaded : false;
}

export function getVentetiderForBehandlingsstedError(state: GlobalState, orgnr: string): string | undefined {
  const ventetiderForBehandlingssted = getVentetiderForBehandlingssted(state, orgnr);
  return ventetiderForBehandlingssted ? ventetiderForBehandlingssted.error : undefined;
}

export function getVentetiderForBehandlingsstedBehandlingsgrupper(
  state: GlobalState,
  orgnr: string
): Array<Behandlingsgruppe> | undefined | null {
  const ventetiderForBehandlingssted = getVentetiderForBehandlingssted(state, orgnr);
  return ventetiderForBehandlingssted ? ventetiderForBehandlingssted.behandlingsGrupper : undefined;
}

/** API */

export function hentVentetiderForBehandlingssted(orgnr: string, resources: HNVelgBehandlingsstedFrontend) {
  return (dispatch: ThunkDispatch<GlobalState, void, GlobalAction>, getState: () => GlobalState): Promise<void> | undefined => {
    const state = getState();

    if (!getVentetiderForBehandlingsstedLoading(state, orgnr) && !getVentetiderForBehandlingsstedLoaded(state, orgnr)) {
      dispatch(loadVentetiderForBehandlingssted(orgnr));

      return get<VentetiderForBehandlingsstedResponse>('VelgBehandlingssted', 'api/v1/VentetiderForBehandlingssted', {
        behandlingsstedId: parseInt(orgnr, 10),
      })
        .then((data: VentetiderForBehandlingsstedResponse) => {
          if (data.ventetiderForBehandlingssted) {
            dispatch(successVentetiderForBehandlingssted(orgnr, data.ventetiderForBehandlingssted.behandlingsGrupper));
          }
        })
        .catch(() => {
          dispatch(failedVentetiderForBehandlingssted(resources.tekniskFeilVentetiderForBehandlingssted, orgnr));
        });
    }
  };
}

export default ventetiderForBehandlingssted;
