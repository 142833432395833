import React from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';

import { HNVelgBehandlingsstedFrontend } from '../types/Resources';

import { LanguageLocales } from '@helsenorge/core-utils/constants/languages';
import { getResourcesFromState } from '@helsenorge/framework-utils/resources';

import {
  hentVentetiderForBehandling,
  getVentetiderForBehandlingByBid,
  getVentetiderForBehandlingError,
  getVentetiderForBehandlingLoading,
  resetErrorVentetiderForBehandling,
} from './ventetider-for-behandling';
import VentetiderForBehandlingComponent from './ventetider-for-behandling-component';
import { GlobalState, GlobalAction } from '../root';
import { HN_VELG_BEHANDLINGSSTED_FRONTEND } from '../utils/resources-project';

const VentetiderForBehandlingContainer: React.FunctionComponent = () => {
  const [searchparams] = useSearchParams();
  const dispatch: ThunkDispatch<GlobalState, void, GlobalAction> = useDispatch();
  const state = useSelector((state: GlobalState) => state);

  const resources = getResourcesFromState(
    state,
    HN_VELG_BEHANDLINGSSTED_FRONTEND,
    LanguageLocales.NORWEGIAN
  ) as HNVelgBehandlingsstedFrontend;

  const ventetiderForBehandlingByBid = getVentetiderForBehandlingByBid(state, searchparams.get('bid'));
  const loading = getVentetiderForBehandlingLoading(state);
  const error = getVentetiderForBehandlingError(state);

  React.useEffect(() => {
    dispatch(hentVentetiderForBehandling(searchparams.get('bid'), resources));

    return (): void => {
      dispatch(resetErrorVentetiderForBehandling());
    };
  }, []);

  return (
    <VentetiderForBehandlingComponent
      resources={resources}
      loading={loading}
      error={error}
      ventetiderForBehandlingByBid={ventetiderForBehandlingByBid}
    />
  );
};

export default VentetiderForBehandlingContainer;
