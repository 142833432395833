import { combineReducers, Reducer } from 'redux';

import hjelpeskuff, { HjelpeskuffState } from '@helsenorge/core-framework/prosesshjelp/hjelpeskuff/hjelpeskuff-state';
import { cmsTjenesteTekster, GlobalStateWithCmsTjenesteTekster } from '@helsenorge/framework-utils/cms-tjeneste-tekster';
import { resources, GlobalStateWithResources } from '@helsenorge/framework-utils/resources';

import behandlinger, { BehandlingerState, BehandlingerAction } from './behandlinger/behandlinger';
import behandlingssteder, { BehandlingsstederState, BehandlingsstederAction } from './behandlingssteder/behandlingssteder';
import ventetiderForBehandlingssted, {
  VentetiderForBehandlingsstedState,
  VentetiderForBehandlingsstedAction,
} from './behandlingssteder/ventetider-for-behandlingssted';
import sokBehadlinger, { SokBehandlingerState, SokBehandlingerAction } from './shared/autosuggest/sok-behandlinger';
import ventetiderForBehandling, {
  VentetiderForBehandlingState,
  VentetiderForBehandlingAction,
} from './ventetider-for-behandling/ventetider-for-behandling';

const rootReducer: Reducer = combineReducers({
  resources,
  hjelpeskuff,
  behandlinger,
  ventetiderForBehandling,
  behandlingssteder,
  ventetiderForBehandlingssted,
  sokBehadlinger,
  cmsTjenesteTekster,
});

export interface GlobalState extends GlobalStateWithResources, GlobalStateWithCmsTjenesteTekster {
  hjelpeskuff: HjelpeskuffState;
  behandlinger: BehandlingerState;
  ventetiderForBehandling: VentetiderForBehandlingState;
  behandlingssteder: BehandlingsstederState;
  ventetiderForBehandlingssted: VentetiderForBehandlingsstedState;
  sokBehadlinger: SokBehandlingerState;
}

export type GlobalAction =
  | BehandlingerAction
  | VentetiderForBehandlingAction
  | BehandlingsstederAction
  | VentetiderForBehandlingsstedAction
  | SokBehandlingerAction;

export default rootReducer;
