import React from 'react';

import { BreadcrumbLink } from '@helsenorge/core-framework/breadcrumb';
import PageRoot from '@helsenorge/core-framework/pageroot/index';
import { getPath, getSidetittelId } from '@helsenorge/framework-utils/hn-page';
import { getVisPersonvelger } from '@helsenorge/framework-utils/hn-user';
import { CommonEvents } from '@helsenorge/framework-utils/web-component/constants';
import { HNeventSetHiddenPromopanel, HNaddEventListener } from '@helsenorge/framework-utils/web-component/events';

import ScrollToTop from '../../ScrollToTop';

interface Props {
  pageClass: string;
  breadcrumbLink?: BreadcrumbLink;
  hasFullwidth?: boolean;
}

const PageContainer: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ children, pageClass, breadcrumbLink, hasFullwidth }) => {
  const webcomppromopanel = document.querySelector('hn-webcomp-cms-block-promopanel');
  const visPersonvelger = getVisPersonvelger();

  HNaddEventListener(CommonEvents.isMounted, webcomppromopanel, () => {
    HNeventSetHiddenPromopanel(!!visPersonvelger);
  });

  React.useEffect(() => {
    HNeventSetHiddenPromopanel(!!visPersonvelger);
  }, []);

  return (
    <PageRoot
      mainAriaLabelledby={getSidetittelId()}
      breadcrumbLink={breadcrumbLink}
      hasPageHeader={false}
      className={`vbs-main ${pageClass}`}
      hasFullwidth={hasFullwidth}
      redirectTo={`/${getPath()}`}
    >
      <ScrollToTop />
      {children}
    </PageRoot>
  );
};
export default PageContainer;
